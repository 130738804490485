import { computed, inject, Injectable, signal } from '@angular/core';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { AuthStatus, User } from '../interfaces';
import { Apollo } from 'apollo-angular';
import { LoginAuth, revalidateAuth } from '../gql/gqlLogin';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private router = inject(Router);
  private _currentUser = signal<User|null>(null);
  private _authStatus =  signal<AuthStatus>(AuthStatus.checking);

  public currentUser = computed(() => this._currentUser());
  public authStatus  = computed(() => this._authStatus());

  constructor(private readonly apollo : Apollo) {
    this.checkAuthStatus().subscribe();
  }

  private setAuthentication(user: User, token: string): boolean {
    this._currentUser.set( user );
    this._authStatus.set( AuthStatus.authenticated );
    localStorage.setItem('token', token);

    return true;
  }

  login( email: string, pass: string ): Observable<boolean>{
    return this.apollo.mutate({
      mutation: LoginAuth,
      variables : {
        loginAuthInput: {
          pass: pass,
          email: email
        }
      }
    })
    .pipe(
      map(({ data } : any) => this.setAuthentication( data.loginAuth.user, data.loginAuth.token )),
      map(() => true),
      catchError(err => throwError(() => err)));
  }

  checkAuthStatus():Observable<boolean> {
    const token = localStorage.getItem('token');

    if ( !token ) {
      this.logout();
      return of(false);
    }

    return this.apollo.query({
      query: revalidateAuth
    })
    .pipe(
      map(({ data } : any) => this.setAuthentication( data.checkTokenAuth.user, data.checkTokenAuth.token )),
      map(() => true),
      catchError(err => throwError(() => err)));
  }

  logout(){
    this.apollo.client.resetStore();
    localStorage.clear();
    this._currentUser.set(null);
    this._authStatus.set( AuthStatus.notAuthenticated );
    this.router.navigate(['/login'], { replaceUrl : true});
  }
}
